<template>
	<div class="mt-4">
		<template v-if="isLoading">
			<b-skeleton
				active
				height="3rem"
			/>
		</template>

		<template v-else>
			<div v-if="semDisciplinas">
				<h3 class="mb-5 has-text-weight-bold invalid-amount">
					Não há disciplinas disponíveis
				</h3>
			</div>

			<div v-else>
				<h3
					class="mb-5 has-text-weight-bold"
					:class="{
						'invalid-amount': invalido,
						'valid-amount': !invalido,
					}"
				>
					{{ totalSelecionadas }} / {{ limiteRealista }} Disciplinas selecionadas para oferta
				</h3>

				<table>
					<thead>
						<tr>
							<th>
								Escolha
							</th>

							<th>
								Código
							</th>

							<th>
								Disciplina
							</th>
						</tr>
					</thead>

					<tbody>
						<tr
							v-for="disciplina in disciplinasNaoDisponiveis"
							:key="disciplina.cocu_id"
						>
							<td class="is-centered">
								<b-tooltip
									label="Você atingiu o limite máximo de disciplinas que podem ser solicitadas neste semestre"
									type="is-danger"
									size="is-small"
									multilined
									:active="opcaoDesativada(disciplina.cocu_id)"
								>
									<b-checkbox
										v-model="selecionadas"
										type="is-primary"
										:native-value="disciplina.cocu_id"
										:disabled="opcaoDesativada(disciplina.cocu_id)"
									/>
								</b-tooltip>
							</td>

							<td>
								{{ disciplina.cocu_codigo }}
							</td>

							<td>
								{{ disciplina.cocu_nome }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div>
				<table>
					<tbody>
						<tr>
							<td class="is-centered">
								<b-tooltip
									label="Você atingiu o limite máximo de disciplinas que podem ser solicitadas neste semestre"
									type="is-danger"
									size="is-small"
									multilined
									:active="opcaoDesativada(NAO_ENCONTREI)"
								>
									<b-checkbox
										v-model="naoEncontrei"
										type="is-primary"
										:disabled="opcaoDesativada(NAO_ENCONTREI)"
									/>
								</b-tooltip>
							</td>

							<td>
								{{ naoEncontreiDescricao }}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</template>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

const NAO_ENCONTREI = '-1';

export default {
	name: 'ListaDisciplinasNaoDisponiveis',

	props: {
		limite: {
			type: Number,
			required: true,
		},
	},

	data() {
		return {
			selecionadas: [],
			NAO_ENCONTREI,
		};
	},

	computed: {
		...mapGetters('solicitacoes', {
			isLoading: 'isLoading',
			disciplinasNaoDisponiveis: 'getDisciplinasNaoDisponiveis'
		}),

		semDisciplinas() {
			return this.disciplinasNaoDisponiveis.length == 0;
		},

		totalSelecionadas() {
			return this.selecionadas.length;
		},

		invalido() {
			return this.totalSelecionadas == 0
				|| this.totalSelecionadas > this.limiteRealista;
		},

		limiteRealista() {
			return Math.min(this.limite, this.disciplinasNaoDisponiveis.length + 1);
		},

		naoEncontrei: {
			get() {
				return this.selecionadas.includes(this.NAO_ENCONTREI);
			},

			set(valor) {
				const index = this.selecionadas.indexOf(this.NAO_ENCONTREI);

				if (valor && index === -1) {
					this.selecionadas.push(this.NAO_ENCONTREI);
				} else if (!valor && index !== -1) {
					this.selecionadas.splice(index, 1);
				}

				this.$emit('naoEncontrei', valor);
			},
		},

		naoEncontreiDescricao() {
			const vinculoSelecionado = this.$store.getters['vinculosAcademicos/getVinculoSelecionado'];

			if (vinculoSelecionado.nens_id == 1) {
				return 'Não encontrei a disciplina/UC\'s';
			}

			return 'Não encontrei a disciplina';
		},
	},

	watch: {
		selecionadas() {
			this.$store.dispatch(
				'solicitacoes/atualizaDisciplinasNaoDisponiveisSelecionadas',
				this.selecionadas
			);
		},
	},

	beforeCreate() {
		this.$store.dispatch('solicitacoes/carregaDisciplinasNaoDisponiveis');
	},

	beforeDestroy() {
		this.$store.dispatch('solicitacoes/limpaDisciplinasNaoDisponiveisSelecionadas');
	},

	methods: {
		opcaoDesativada(cocu_id) {
			return !this.selecionadas.includes(cocu_id)
				&& this.totalSelecionadas >= this.limiteRealista;
		},
	},
}
</script>

<style lang="scss" scoped>
.valid-amount {
	color: #00796A;
}

.invalid-amount {
	color: #FF1515;
}

th, td {
	border: 1.5rem transparent solid;
}

th {
	border-top: 0;
}

th:first-child,
td:first-child {
	border-left: 0;
}

th:last-child,
td:last-child {
	border-right: 0;
}
</style>
