<template>
	<div>
		<div
			v-if="listaAnexos.length > 0"
			class="detail-attachment"
		>
			<h4 class="is-size-6 mb-2">
				Anexos
			</h4>

			<table>
				<thead>
					<tr>
						<th>ID</th>

						<th>Descrição</th>

						<th>Tamanho</th>

						<th>&nbsp;</th>
					</tr>
				</thead>

				<tbody>
					<tr
						v-for="(anexo, contador) in listaAnexos"
						:key="anexo.id"
						class="iteration-footer-item"
					>
						<td>{{ contador + 1 }}</td>

						<td>{{ anexo.descricao }}</td>

						<td>{{ anexo.tamanho }}</td>

						<td>
							<span
								class="attachment-download"
								@click="download(anexo.id, anexo.extensao)"
							>
								Baixar
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { downloadFile } from '@commons/helpers.js';

const numberFormatter = new Intl.NumberFormat('pt-BR', {
	maximumFractionDigits: 2,
}).format;

const calculateSize = file => {
	const letters = 'KMGT';

	let base = 1;
	let unit = '';

	for (let i = 0; i < 4; i++) {
		let nextBase = base * 1024;

		if (file >= nextBase) {
			base = nextBase;
			unit = letters.charAt(i);
		}
	}

	return numberFormatter(file / base) + ' ' + unit + 'B';
};

export default {
	name: 'DetalheAnexo',

	props: {
		anexos: {
			type: Array,
			required: true,
		},

		requId: {
			type: Number,
			required: true,
		},
	},

	computed: {
		listaAnexos() {
			const anexos = [];

			if (!this.anexos || !this.anexos.length) {
				return anexos;
			}

			for (const anexo of this.anexos) {
				anexos.push({
					'id': parseInt(anexo.rean_id, 10),
					'descricao': anexo.rean_descricao,
					'tamanho': calculateSize(anexo.rean_tamanho),
					'extensao': anexo.rean_extensao,
				});
			}

			return anexos.sort((a, b) => a.id - b.id);
		}
	},

	methods: {
		async download(rean_id, extensao) {
			try {
				this.$store.dispatch('ui/toast/openInfo', 'Iniciando o download');

				const anexo = await this.$store.dispatch('solicitacoes/obterArquivo', { requ_id: this.requId, rean_id });
				downloadFile(
					anexo.data,
					rean_id + '.' + extensao,
					anexo.mime
				);
			} catch (e) {
				this.$store.dispatch('ui/toast/openError', 'Não foi possível baixar esse anexo, tente novamente');
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.detail-attachment {
	margin-top: 2.5rem;
	margin-left: 1.5rem;
	margin-right: 1rem;
	display: flex;
	flex-direction: column;
	font-size: 0.875rem;

	table {
		th,
		tr:nth-child(even) {
			background-color: rgba(0, 0, 0, 0.1);
		}

		th,
		td {
			padding: 0.5rem 1rem;
		}

		.attachment-download {
			cursor: pointer;
			font-weight: 700;
		}
	}
}
</style>
