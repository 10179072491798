<template>
	<div class="column is-5-desktop is-12 is-flex is-flex-direction-column is-align-items-center">
		<b-tooltip
			class="tooltip-em-aberto"
			animated
			multilined
			:active="!boleto.bloqueio.permitir_cartao"
			:label="boleto.bloqueio.msg_bloqueio"
			always
		>
			<b-button
				v-if="!boleto.espera_operadora"
				:disabled="!boleto.bloqueio.permitir_cartao"
				class="mb-4 is-fullwidth button is-primary is-rounded"
				@click="payWithCard(boleto)"
			>
				Pague com o cartão de crédito
			</b-button>
		</b-tooltip>
		<b-tooltip
			class="tooltip-em-aberto"
			animated
			multilined
			position="is-bottom"
			:active="!boleto.bloqueio.permitir_boleto"
			:label="boleto.bloqueio.msg_bloqueio"
			always
		>
			<b-button
				:disabled="!boleto.bloqueio.permitir_boleto"
				class="mb-4 button is-primary is-fullwidth is-rounded"
				@click.prevent="
					handleInvoice(
						boleto.doc_id,
						boleto.bloqueio.permitir_boleto
					)"
			>
				Gerar boleto
			</b-button>
			<b-button
				:disabled="!boleto.bloqueio.permitir_boleto"
				class="mb-4 button is-fullwidth is-primary is-rounded is-outlined"
				@click.prevent="
					copyToClipboard(
						boleto.doc_codigo_barras,
						boleto.bloqueio.permitir_boleto
					)"
			>
				Copiar código de barras
			</b-button>
			<b-button
				v-if="boleto.nota_fiscal"
				:disabled="!(boleto.nota_fiscal.link_retorno || boleto.nota_fiscal.mensagem)"
				class="mb-4 button is-fullwidth is-primary is-rounded is-outlined"
				@click.prevent="openLinkOrError(boleto.nota_fiscal.link_retorno, boleto.nota_fiscal.mensagem, boleto.nota_fiscal)"
			>
				Nota Fiscal
			</b-button>
		</b-tooltip>
		<b-modal
			v-if="modalData"
			:active="true"
			:is-full-page="true"
			:can-cancel="false"
		>
			<b-loading
				v-model="loading"
				:is-full-page="true"
				:can-cancel="false"
			/>
			<div class="card">
				<div class="card-content">
					<h1 class="is-flex title is-1 has-text-centered">
						Nota Fiscal
					</h1>
					<p>
						<strong>Informações para nota fiscal.</strong><br>
						Informamos que a nota fiscal está disponível para impressão.<br>
						Acesse em:
					</p>
					<a
						:href="modalData.link_retorno"
						target="_blank"
					>
						{{ modalData.link_retorno }}
					</a>
					<p>
						<strong>Algumas informações abaixo podem ser necessárias para acesso:</strong>
					</p>
					<ul>
						<li class="nf-list-item">
							<strong>CNPJ Prestador:</strong> {{ modalData.cnpjPrestador }}
						</li>
						<li class="nf-list-item">
							<strong>IM Prestador:</strong> {{ modalData.imPrestador }}
						</li>
						<li class="nf-list-item">
							<strong>Número NFS-e:</strong> {{ modalData.numeroNf }}
						</li>
						<li class="nf-list-item">
							<strong>Data de emissão:</strong> {{ modalData.dataEmissao }}
						</li>
						<li class="nf-list-item">
							<strong>Número do RPS:</strong> {{ modalData.numeroRps }}
						</li>
						<li class="nf-list-item">
							<strong>Série RPS:</strong> {{ modalData.serieRps }}
						</li>
						<li class="nf-list-item">
							<strong>Código de verificação:</strong> {{ modalData.codigoAutenticacao }}
						</li>
						<li class="nf-list-item">
							<strong>Valor do Serviço:</strong> {{ modalData.valorServico }}
						</li>
					</ul>
					<b-button
						class="mb-2 mt-5"
						label="Fechar"
						type="is-primary is-rounded"
						@click="closeNfNotification"
					/>
				</div>
			</div>
		</b-modal>
	</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
	name: 'FormasPagamento',
	props: {
		boleto: {
			type: [Object, Array],
			default: () => {}
		},
	},
	data() {
		return {
			loading: false,
			modalData: null,
		};
	},
	methods: {
		...mapActions('pagamentos', [
			'gerarBoleto',
			'gerarComprovanteCartao'
		]),

		payWithCard(boleto) {
			if (!boleto.bloqueio.permitir_cartao) {
				return;
			}

			// this.$store.commit('setFatura', boleto);
			// this.$router.push({
			// 	name: 'pagamento-cartao',
			// 	params: { fatura: boleto },
			// });
			this.$emit('handleFormCartao', true);
		},

		async handleInvoice(id, permitir = true) {
			if (!permitir) {
				return;
			}

			try {
				this.$buefy.toast.open({
					message: 'Download em andamento!',
					type: 'is-success',
				});

				this.$store.dispatch('ui/loading', true);
				const response = await this.gerarBoleto(id);

				if (response) return;

			} catch (error) {
				this.$buefy.toast.open({
					message: 'Erro ao gerar boleto!',
					type: 'is-danger',
				});
			} finally {
				this.$store.dispatch('ui/loading', false);
				this.$emit('retorno-boleto', null);
			}
		},

		copyToClipboard(barcode, permitir = true) {
			if (!permitir) {
				return;
			}

			const copy = navigator.clipboard.writeText(barcode);

			if (copy) {
				this.$buefy.toast.open({
					message: 'Código de barras copiado com sucesso!',
					type: 'is-success',
				});
			}

			this.$emit('retorno-boleto', null);
		},

		openLinkOrError(link, error, infos) {
			if (error) {
				this.$store.dispatch('ui/toast/openError', error);

				return;
			}

			this.modalData = {
				link_retorno: infos.link_retorno,
				cnpjPrestador: infos.cnpj_prestador,
				imPrestador: infos.im_prestador,
				numeroNf: infos.numero_nf,
				dataEmissao: infos.data_emissao,
				numeroRps: infos.numero_rps,
				serieRps: infos.serie_rps,
				codigoAutenticacao: infos.codigo_verificacao,
				valorServico: infos.valor_nota
			};
		},
		closeNfNotification() {
			this.modalData = null; // Ou qualquer valor que indique que a modal deve ser fechada
		},
	}
}
</script>

<style>
	.tooltip-em-aberto {
		width: 100%;
	}
</style>
