<template>
	<b-modal
		v-model="modalModel"
		:width="modalSize"
		scroll="keep"
		:can-cancel="false"
	>
		<div class="card">
			<div class="card-head" />
			<div class="card-content">
				<div class="column is-12">
					<h1 class="is-size-4 has-text-centered">
						Atualização cadastral
					</h1>
				</div>

				<div class="column is-12 has-text-centered">
					<p>Certifique que seus dados cadastrais estão corretos e confirme abaixo as alterações:</p>
				</div>

				<div class="column is-12 is-flex is-justify-content-center">
					<div class="is-flex is-justify-content-center is-flex-direction-column">
						<div class="is-flex is-flex-direction-column py-5">
							<b-field>
								<b-checkbox v-model="aceitouEmail">
									<strong>Email</strong>
								</b-checkbox>
							</b-field>

							<span>De: {{ emailAntes }}</span>
							<span>Para: {{ emailDepois }}</span>
						</div>

						<div class="is-flex is-flex-direction-column py-5">
							<b-field>
								<b-checkbox v-model="aceitouTelefone">
									<strong>Telefone</strong>
								</b-checkbox>
							</b-field>

							<span>De: {{ celularAntes }}</span>
							<span>Para: {{ celularDepois }}</span>
						</div>
					</div>
				</div>

				<div class="column is-12 has-text-centered has-text-weight-bold">
					<p>Fique atento: Lembre-se de manter os dados cadastrais e de contato sempre atualizados.</p>
					<p>Assim, você fica por dentro de assuntos de interesse sobre o seu curso e Instituição.</p>
					<p>É de responsabilidade do aluno o fornecimento de dados cadastrais corretos.</p>
				</div>

				<div
					class="column is-offset-6-desktop is-6-desktop is-flex is-justify-content-space-between is-align-items-center"
					:class="{
						'is-flex-direction-column': isMobile
					}"
				>
					<span
						class="is-clickable has-text-weight-bold"
						:class="{'mt-5': isMobile}"
						@click="closeModal"
					>
						Voltar
					</span>

					<button
						:disabled="!aceitouEmail && !aceitouTelefone"
						type="submit"
						class="button is-primary is-rounded"
						:class="{'mt-5': isMobile}"
						@click="saveData"
					>
						Continuar
					</button>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
	name: 'NovaSolicitacaoModalAtualizaDados',
	props: {
		openModal: {
			type: Boolean,
			default: false
		},
		celularAntes: {
			type: String,
			default: ""
		},
		celularDepois: {
			type: String,
			default: ""
		},
		emailAntes: {
			type: String,
			default: ""
		},
		emailDepois: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			aceitouTelefone: false,
			aceitouEmail: false
		};
	},
	computed: {
		...mapGetters('ui', [
			'isMobile'
		]),

		modalModel: {
			get() {
				return this.openModal;
			},

			set() {
				this.closeModal();
			},
		},

		modalSize() {
			return this.$store.getters['ui/isMobile'] ? '95vw' : '70vw';
		},
	},
	methods: {
		...mapMutations('aluno/dadosPessoais', [
			'setEmail',
			'setCelular'
		]),

		...mapActions('aluno/dadosPessoais', [
			'atualizaDados'
		]),

		async saveData() {
			this.atualizaDados({
				pess_email: this.aceitouEmail ? this.emailDepois : this.emailAntes,
				pess_fone_celular: this.aceitouTelefone ? this.celularDepois.replace(/\D/g, "") : this.celularAntes.replace(/\D/g, ""),
			}).then(() => {
				this.$buefy.toast.open({
					duration: 5000,
					message: `Dados atualizados com sucesso!`,
					position: 'is-top',
					type: 'is-success'
				});
				this.setEmail(this.emailDepois);
				this.setCelular(this.celularDepois);
			});

			this.closeModal();
		},

		closeModal() {
			this.aceitouTelefone = false;
			this.aceitouEmail = false;
			this.$emit('close');
		}
	}
}
</script>

<style lang="scss" scoped>
	::v-deep {
		.b-checkbox.checkbox input[type=checkbox]:checked + .check {
			background-color:  var(--primary) !important;
			border-color: var(--primary) !important;
		}

		.b-checkbox.checkbox:hover input[type=checkbox]:hover + .check {
			border-color: var(--primary) !important;
		}
	}
</style>
