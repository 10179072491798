<template>
	<div class="card-content is-flex is-flex-direction-column is-align-items-center">
		<div class="column is-12 has-text-centered">
			<h1 class="is-size-2 payment has-text-weight-bold">
				Pagamento da solicitação
			</h1>

			<p class="is-size-5 ">
				Escolha a forma de pagamento para <strong>{{ detalhesRequerimento.reqt_descricao | capitalizeAllWords }}</strong>
				<br>
				de número <strong>{{ requId }}</strong>, nas opções abaixo:
			</p>
		</div>

		<div
			class="column is-12 container-formas-pagamento"
			:class="{ 'is-flex': !isMobile }"
		>
			<div
				class="column is-6-desktop is-12 is-flex-direction-column descricao-pagamento"
				:class="{
					'is-align-items-center': isMobile,
					'is-flex': !isMobile
				}"
			>
				<div :class="['open', 'center-on-mobile', isMobile ? 'has-text-centered' : '']">
					<b-icon
						size="is-small"
						icon="alert-circle-outline"
						class="alert"
					/>
					<span class="is-size-6 has-text-weight-bold alert"> Valor a Pagar</span>
				</div>

				<p
					class="is-size-4 center-on-mobile has-text-weight-bold pb-4"
					:class="{
						'has-text-centered': isMobile
					}"
				>
					{{ detalhesRequerimento.doc.valor_titulo | valorPTBR }}
				</p>
				<p class="is-size-6">
					Curso: <strong>{{ getVinculoSelecionado.curs_nome | capitalizeAllWords }}</strong>
				</p>
				<p class="is-size-6">
					Data da solicitação:
					<strong>{{ dateNow }}</strong>
				</p>
				<p class="is-size-6">
					Assunto: <strong> {{ getCategoriaSelecionada.reca_nome | capitalizeAllWords }} </strong>
				</p>

				<p class="is-size-6">
					Solicitação: <strong> {{ getTipoSelecionado.reqt_descricao | capitalizeAllWords }} </strong>
				</p>
			</div>

			<div class="column vertical-divider is-1-desktop is-hidden-touch" />

			<div
				class="is-12 is-5-tablet is-5-desktop is-flex is-justify-content-center"
				:class="{ 'column': isMobile }"
			>
				<FormasPagamento
					v-if="openFormCartao == false"
					class="is-12-desktop"
					:boleto="fatura"
					@handleFormCartao="handleCartao"
					@retorno-boleto="retornoPagamento(null)"
				/>
				<Pagamento
					v-if="openFormCartao"
					:fatura="fatura"
					:using-on-modal="true"
					@handleFormCartao="handleCartao"
					@retorno-pagamento="retornoPagamento"
				/>
			</div>
		</div>

		<div class="column is-12">
			<p class="column is-offset-1 is-10 has-text-centered">
				Para concluir esta solicitaçao é necessário realizar o pagamento em <strong>até {{ getTipoSelecionado.reqt_prazo_pagamento }} dias úteis</strong> após o pedido.
				<br>
				Caso não consiga realizar o pagamento agora, clique em <span class="is-underlined">Continuar Sem Pagar Agora.</span>"
			</p>
		</div>


		<div class="column is-12 is-flex is-justify-content-flex-end">
			<div class="column is-6 is-flex is-justify-content-flex-end">
				<button
					class="button is-primary is-rounded"
					@click="retornoPagamento(null)"
				>
					Continuar sem pagar agora
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import FormasPagamento from '@components/solicitacoes/FormasPagamento'
import Pagamento from '@components/meus_pagamentos/Pagamento'
import { capitalizeAll } from '@commons/helpers';
import moment from 'moment';

export default {
	name: 'NovaSolicitacaoModalPagamento',
	components: {
		FormasPagamento,
		Pagamento
	},
	filters: {
		capitalizeAllWords(value) {
			return capitalizeAll(value);
		},

		valorPTBR(value) {
			return Intl.NumberFormat('pt-BR', {style:'currency', currency: 'BRL'}).format(value)
		}
	},
	props: {
		requId: {
			type: [String, Number],
			default: null
		},
	},
	data() {
		return {
			openFormCartao: false,
		}
	},
	computed: {
		...mapGetters('solicitacoes', [
			'detalhes',
			'getCategoriaSelecionada',
			'getTipoSelecionado'
		]),

		...mapGetters('ui', [
			'isMobile'
		]),

		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado'
		]),

		detalhesRequerimento() {
			return this.detalhes(this.requId);
		},

		dateNow() {
			return moment().format('DD/MM/YYYY');
		},

		fatura() {
			return this.$store.getters['pagamentos/getById'](
				this.detalhesRequerimento.doc.doc_id
			);
		},
	},
	methods: {
		...mapMutations('solicitacoes', [
			'setModalActive',
			'setActiveStep',
			'setProgress',
			'setRetornoPagamento'
		]),
		handleCloseModal() {
			this.setProgress(0);
			this.setModalActive(false);
		},
		goBack() {
			this.setActiveStep('aviso');
			this.setProgress(33);
		},
		handleCartao(value) {
			this.openFormCartao = value;
		},
		retornoPagamento(value) {
			this.setRetornoPagamento(value);
			this.setActiveStep('sucesso');
			this.setProgress(100);
		}
	},
}
</script>

<style>
	.alert {
		color: #FF1515
	}

	.main-warning {
		color: #525252
	}

	.payment {
		color: #777777
	}

	@media screen and (max-width: 1000px) {
		.container-formas-pagamento {
			flex-direction: column !important;
			align-items: center !important;
		}

		.descricao-pagamento {
			align-items: center !important;
		}
	}
</style>
