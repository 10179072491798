<template>
	<b-modal
		v-model="isModalActive"
		:width="modalSize"
		scroll="keep"
		:can-cancel="false"
	>
		<div class="card">
			<div class="card-head">
				<div class="is-flex is-justify-content-space-between pt-3">
					<div class="column is-3 mt-5 ml-6">
						<div class="is-flex is-justify-content-space-around pb-2">
							<span :class="[{'progress-label-active': getActiveStep == 'aviso'}, 'is-size-7 disable-text']">Etapa 1</span>
							<span
								v-if="contemCobranca"
								:class="[{'progress-label-active': getActiveStep == 'pagamento'}, 'is-size-7 disable-text']"
							>Etapa 2</span>
							<span :class="[{'progress-label-active': getActiveStep == 'sucesso'}, 'is-size-7 disable-text']">Etapa {{ contemCobranca ? '3' : '2' }}</span>
						</div>

						<b-progress
							type="is-success"
							:value="getProgress"
							size="is-small"
						/>
					</div>
					<div class="column is-1 is-flex is-justify-content-center">
						<span
							class="is-size-3 is-clickable"
							@click="handleCloseModal"
						>X</span>
					</div>
				</div>
			</div>

			<NovaSolicitacaoModalAviso
				v-if="getActiveStep == 'aviso'"
				@created="requId = $event"
			/>

			<NovaSolicitacaoModalPagamento
				v-if="getActiveStep == 'pagamento'"
				:requ-id="requId"
			/>

			<NovaSolicitacaoModalSucesso
				v-if="getActiveStep == 'sucesso'"
				:requ-id="requId"
				@go-to="$emit('go-to', $event)"
			/>
		</div>
	</b-modal>
</template>

<script>
import NovaSolicitacaoModalAviso from "@components/solicitacoes/modal/NovaSolicitacaoModalAviso"
import NovaSolicitacaoModalPagamento from "@components/solicitacoes/modal/NovaSolicitacaoModalPagamento"
import NovaSolicitacaoModalSucesso from "@components/solicitacoes/modal/NovaSolicitacaoModalSucesso"
import { mapMutations, mapGetters } from 'vuex';

export default {
	name: 'NovaSolicitacaoModal',
	components: {
		NovaSolicitacaoModalAviso,
		NovaSolicitacaoModalPagamento,
		NovaSolicitacaoModalSucesso,
	},
	data() {
		return {
			requId: 0,
		};
	},
	computed: {
		...mapGetters('solicitacoes', [
			'getProgress',
			'isModalActive',
			'getActiveStep',
			'contemCobranca',
		]),
		modalSize() {
			return this.$store.getters['ui/isMobile'] ? '95vw' : '70vw';
		}
	},
	created() {
		if (this.contemCobranca) {
			this.setProgress(33)
		} else {
			this.setProgress(50)
		}
	},
	methods: {
		...mapMutations('solicitacoes', [
			'setModalActive',
			'setProgress',
			'setActiveStep'
		]),
		handleCloseModal() {
			this.setProgress(0);
			this.setModalActive(false);
			this.setActiveStep('aviso');
		}
	}
}
</script>

<style lang="scss" scoped>
	.disable-text {
		color: #C2C2C2
	}

	.progress-label-active {
		color: #525252;
	}

</style>
