<template>
	<b-modal 
		v-model="getOpenDetalheFeedbackModal" 
		class="modal-feedback" 
		:can-cancel="false" 
		:width="1200"
	>
		<div class="card">
			<b-loading 
				v-model="isLoading" 
				:is-full-page="true" 
				:can-cancel="false" 
			/>
			<div class="card-head">
				<div class="is-flex is-justify-content-space-between pt-3">
					<div :class="[isMobile?'':'ml-6', 'column is-3 mt-5']">
						<div class="is-flex is-justify-content-space-around pb-2">
							<span :class="[{'progress-label-active': getActiveStep == 'detalhe'}, 'is-size-7 disable-text']">Passo 1</span>
							<span :class="[{'progress-label-active': getActiveStep == 'feedback'}, 'is-size-7 disable-text']">Passo 2</span>
						</div>

						<b-progress
							type="is-success"
							:value="getProgress"
							size="is-small"
						/>
					</div>
					<div class="column is-1 is-flex is-justify-content-center">
						<span
							class="is-size-3 is-clickable"
							@click="handleCloseModal"
						>X</span>
					</div>
				</div>
			</div>
			
			<Detalhe
				v-if="getActiveStep == 'detalhe'"	
				:id="requId"
				:is-modal="true"
				@nextStep="nextStep"
			/>

			<FeedbackForm 
				v-if="getActiveStep == 'feedback'"
				:requ-id="requId" 
				:row="row" 
				@skipFeedback="skipFeedback"
				@sendFeedback="sendFeedback"
			/>
		</div>
	</b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import http from '@commons/http'
import FeedbackForm from '@components/solicitacoes/modal/FeedbackForm'
import Detalhe from '@components/solicitacoes/Detalhe';

export default {
	name: 'AvaliarSolicitacaoModal',
	components: { FeedbackForm, Detalhe },
	props: {
		requId: {
			type: Number,
			default: 0
		},

		row: {
			type: Object,
			default: () => { }
		}
	},

	data() {
		return {
			loading: false,
		}
	},

	computed: {
		...mapGetters('ui', {
			isMobile: 'isMobile',
		}),

		...mapGetters('solicitacoes', [
			'getOpenDetalheFeedbackModal',
			'isLoading',
			'getProgress',
			'isModalActive',
			'getActiveStep',
		]),

	},
	methods: {
		...mapMutations('solicitacoes', [
			'setOpenModalDetalhe',
			'setLoading',
			'setModalActive',
			'setProgress',
			'setActiveStep'
		]),

		async sendFeedback(atendimento) {
			try {

				this.setLoading(true);

				const feedbackFinished = {
					reav_problema_resolvido: atendimento.resolvido,
					reav_tempo_resposta: atendimento.tempo,
					reav_nosso_resolver: parseInt(atendimento.nota, 10),
					reav_detalhe: atendimento.feedback,
				};

				await http.post(`/v1/aluno/requerimentos/${this.requId}/avaliacao`,
					new URLSearchParams(feedbackFinished).toString()
				)

				this.handleToDetails();

				this.setLoading(false);

				this.$buefy.toast.open({
					message: 'Avaliação enviada com sucesso!',
					type: 'is-success'
				});

				this.setOpenModalDetalhe(false);
			} catch (error) {
				console.warn(error)

				this.setLoading(false);

				this.$buefy.toast.open({
					message: 'Erro ao enviar avaliação. Tente novamente!',
					type: 'is-danger'
				});

				this.setOpenModalDetalhe(false);
			}
		},

		async skipFeedback() {
			try {
				this.handleToDetails();
				this.setOpenModalDetalhe(false);

				await http.post(`/v1/aluno/requerimentos/${this.requId}/avaliacao`,
					new URLSearchParams({ pular: true }).toString()
				)
			} catch (error) {
				console.warn(error)

				this.$buefy.toast.open({
					message: 'Erro ao pular avaliação. Tente novamente!',
					type: 'is-danger'
				});

				this.setOpenModalDetalhe(false);
			}
		},

		handleToDetails() {
			if (this.$route.name === 'solicitacoes') {
				this.$emit('handleDetailAction', this.row)
			}
		},

		nextStep(){
			this.setActiveStep('feedback');
			this.setProgress(100);
		},

		handleCloseModal() {
			this.setProgress(0);
			this.setOpenModalDetalhe(false);
			this.setActiveStep('detalhe');
		}

	}

}
</script>

<style lang="scss" scoped>

	.progress-label-active {
		color: #525252;
	}

</style>