<template>
	<b-modal 
		v-model="isModalActive" 
		class="modal-feedback" 
		:can-cancel="false" 
		:width="1200"
	>
		<div class="card">
			<b-loading 
				v-model="isLoading" 
				:is-full-page="true" 
				:can-cancel="false" 
			/>
			<FeedbackForm 
				:requ-id="requId" 
				:row="row" 
				@skipFeedback="skipFeedback"
				@sendFeedback="sendFeedback"
			/>
		</div>
	</b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import http from '@commons/http'
import FeedbackForm from '@components/solicitacoes/modal/FeedbackForm'

export default {
	name: 'AcompanhamentoFeedbackModal',
	components: { FeedbackForm },
	props: {
		requId: {
			type: [Number, String],
			default: 0
		},

		row: {
			type: Object,
			default: () => { }
		}
	},

	data() {
		return {
			loading: false,
		}
	},

	computed: {
		isModalActive: {
			get() {
				return this.getOpenFeedbackModal
			},
		},

		...mapGetters('ui', {
			isMobile: 'isMobile',
		}),

		...mapGetters('solicitacoes', [
			'getOpenFeedbackModal',
			'isLoading'
		]),

	},
	methods: {
		...mapMutations('solicitacoes', [
			'setOpenModalFeedback',
			'setLoading'
		]),

		async sendFeedback(atendimento) {
			try {

				this.setLoading(true);

				const feedbackFinished = {
					reav_problema_resolvido: atendimento.resolvido,
					reav_tempo_resposta: atendimento.tempo,
					reav_nosso_resolver: parseInt(atendimento.nota, 10),
					reav_detalhe: atendimento.feedback,
				};

				await http.post(`/v1/aluno/requerimentos/${this.requId}/avaliacao`,
					new URLSearchParams(feedbackFinished).toString()
				)

				this.setLoading(false);

				this.$buefy.toast.open({
					message: 'Avaliação enviada com sucesso!',
					type: 'is-success'
				});

				this.setOpenModalFeedback(false);

				this.handleToDetails();

			} catch (error) {
				console.warn(error)

				this.setLoading(false);

				this.$buefy.toast.open({
					message: 'Erro ao enviar avaliação. Tente novamente!',
					type: 'is-danger'
				});

				this.setOpenModalFeedback(false);
			}
		},

		async skipFeedback() {
			try {
				this.setOpenModalFeedback(false);

				await http.post(`/v1/aluno/requerimentos/${this.requId}/avaliacao`,
					new URLSearchParams({ pular: true }).toString()
				)
			} catch (error) {
				console.warn(error)

				this.$buefy.toast.open({
					message: 'Erro ao pular avaliação. Tente novamente!',
					type: 'is-danger'
				});

				this.setOpenModalFeedback(false);
			}
		},

		
		handleToDetails() {
			if (this.$route.name === 'solicitacoes') {
				this.$emit('handleDetailAction', this.row)
			}
		},

	}

}
</script>