<template>
	<div class="card-content is-flex is-flex-direction-column is-align-items-center columns">
		<div class="column is-12 has-text-centered">
			<h1 class="is-size-2 alert has-text-weight-bold">
				Atenção!
			</h1>
		</div>

		<div class="column is-8 main-warning">
			<p
				id="reqt_descricao"
				class="is-size-5 has-text-centered"
				v-html="getOrientacaoRequerimento"
			/>

			<br>

			<p class="is-size-5 has-text-centered">
				Não se esqueça de acompanhar a solicitação aqui no Portal do Estudante
				através do Portal do Estudante > Solicitações > Acompanhamento de
				Solicitações
			</p>

			<div
				v-if="contemCobranca"
				class="mt-6 is-size-5 has-text-centered payment"
			>
				<div class="is-flex is-justify-content-center is-align-items-center">
					<Icon
						:file="'dolar'"
						:size="20"
						class="pr-3 pt-1"
					/>

					<span>
						Esta solicitação é paga
					</span>
				</div>

				<span>
					Para concluir, você deverá efetuar o pagamento no valor de
					<span class="is-size-4 has-text-weight-bold">{{
						getValorCobranca | valorPTBR
					}}</span>
				</span>
			</div>
		</div>

		<div class="is-offset-6-desktop is-6-desktop is-12-tablet column columns">
			<div class="column is-4 pt-5 has-text-centered">
				<span
					class="is-clickable is-size-5 has-text-weight-bold"
					@click="handleCloseModal"
				>
					Voltar
				</span>
			</div>

			<div class="column is-offset-1 is-7">
				<button
					class="button is-primary is-rounded is-12-mobile"
					@click="nextStep"
				>
					Abrir Requerimento
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@components/Icon';
import { mapGetters, mapMutations } from 'vuex';

export default {
	name: 'NovaSolicitacaoModalAviso',
	components: {
		Icon
	},
	filters: {
		valorPTBR(value) {
			return Intl.NumberFormat('pt-BR', {
				style: 'currency',
				currency: 'BRL'
			}).format(value);
		}
	},
	computed: {
		...mapGetters('solicitacoes', [
			'getValorCobranca',
			'contemCobranca',
			'getOrientacaoRequerimento'
		])
	},
	methods: {
		...mapMutations('solicitacoes', [
			'setModalActive',
			'setActiveStep',
			'setProgress'
		]),
		handleCloseModal() {
			this.setProgress(0);
			this.setModalActive(false);
		},
		async nextStep() {
			try {
				this.$store.dispatch('ui/loading', true);

				const requId = await this.$store.dispatch('solicitacoes/criar');

				const requisicoes = [
					this.$store.dispatch('solicitacoes/carregaSolicitacaoDetalhe', requId)
				];

				if (this.contemCobranca) {
					requisicoes.push(this.$store.dispatch('pagamentos/todosPagamentos'));
				}

				await Promise.all(requisicoes);

				this.$emit('created', requId);

				if (this.contemCobranca) {
					this.setProgress(66);
					this.setActiveStep('pagamento');
					return;
				}

				this.setProgress(100);
				this.setActiveStep('sucesso');
			} catch (e) {
				console.log(e.data.message);
				this.$store.dispatch('ui/toast/openError', String(e.data.message));
			} finally {
				this.$store.dispatch('ui/loading', false);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
.alert {
	color: #ff1515;
}

.main-warning {
	color: #525252;
	max-width: 95vw;
}

.payment {
	color: #777777;
}

@media screen and (max-width: 768px) {
	.is-12-mobile {
		width: 100%;
	}
}
::v-deep {
	#reqt_descricao{
		word-wrap: break-word;
		ul,ol{
			list-style: unset;
		}
	}
}
</style>
