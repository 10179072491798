<template>
	<div>
		<div class="columns is-gapless is-flex-direction-row-reverse">
			<DetalheInfo
				class="column is-one-thirds is-full-mobile"
				:solicitacao="solicitacao"
			/>

			<div class="column is-two-thirds is-full-mobile my-4">
				<div
					v-if="showButtonNewFeedback && !isModal"
					class="box-avaliar pr-6 pl-6"
				>
					<b-button
						v-if="!isMobile"
						class="is-primary is-rounded is-fullwidth mt-4"
						@click="handleModalFeedback"
					>
						Avaliar o atendimento desta solicitação
					</b-button>

					<b-button
						v-else
						class="is-primary is-rounded is-fullwidth"
						@click="handleModalFeedback"
					>
						Avaliar o atendimento
					</b-button>
				</div>

				<div
					v-if="hasFeedback && !isModal"
					class="columns is-align-items-center px-5"
				>
					<div class="column is-12-desktop is-12-fullhd is-12-mobile">
						<div
							class="is-flex is-flex-direction-row mt-4"
							:class="{ 'is-justify-content-space-between': isMobile }"
						>
							<div
								class="is-flex is-flex-direction-column is-align-items-center"
								:class="{ 'mx-2': !isMobile }"
							>
								<div class="is-flex is-align-items-center is-justify-content-center mb-1">
									<Icon
										class="icon-text mr-2"
										file="solicitacao/target-arrow"
										size="15"
									/>
									<span class="is-size-7">{{ isMobile ? 'Solicitação resolvida?' : 'Sua solicitação foi resolvida?' }}</span>
								</div>
								<p class="is-size-5 has-text-weight-bold mt-3">
									<Icon
										v-if="solicitacao.avaliacao.reav_problema_resolvido == 't'"
										file="solicitacao/like-nobg"
										size="40"
									/>
									<Icon
										v-if="solicitacao.avaliacao.reav_problema_resolvido != 't'"
										file="solicitacao/dislike-nobg"
										size="40"
									/>
								</p>
							</div>
							<hr
								v-if="!isMobile"
								class="vertical-divider-feedback ml-4 mr-4"
							>
							<div
								class="is-flex is-flex-direction-column is-align-items-center"
								:class="{ 'mx-2': !isMobile }"
							>
								<div class="is-flex is-align-items-center is-justify-content-center mb-1">
									<b-icon
										class="mr-1"
										icon="clock-outline"
										size="is-small"
									/>
									<span class="is-size-7">{{ isMobile ? 'Tempo resposta' : 'Tempo de resposta' }}</span>
								</div>
								<p class="is-size-5 has-text-weight-bold mt-3">
									<Icon
										v-if="solicitacao.avaliacao.reav_tempo_resposta == 't'"
										file="solicitacao/like-nobg"
										size="40"
									/>
									<Icon
										v-if="solicitacao.avaliacao.reav_tempo_resposta != 't'"
										file="solicitacao/dislike-nobg"
										size="40"
									/>
								</p>
							</div>
							<hr
								v-if="!isMobile"
								class="vertical-divider-feedback ml-4 mr-4"
							>
							<div
								class="is-flex is-flex-direction-column ml-2 is-align-items-center"
								:class="{ 'mx-2': !isMobile }"
							>
								<div class="is-flex is-align-items-center is-justify-content-center mb-1">
									<b-icon
										class="mr-2"
										icon="cogs"
										size="is-small"
									/>
									<span class="is-size-7">Avaliação</span>
								</div>
								<p class="is-size-5 has-text-weight-bold mt-3">
									<span class="is-flex-direction-column is-flex has-text-centered">
										<Icon
											class="has-text-centered"
											:file="'solicitacao/' + avaliacaoNota + '-enabled'"
											size="40"
										/>
										<small>{{ textoNota }}</small>
									</span>
								</p>
							</div>
						</div>

						<div
							v-if="solicitacao.avaliacao.reav_detalhe != null && solicitacao.avaliacao.reav_detalhe != ''"
							class="mt-3"
						>
							<span>
								<span class="has-text-weight-bold">Seu comentário:</span> {{ solicitacao.avaliacao.reav_detalhe }}
							</span>
						</div>
					</div>
				</div>

				<hr>

				<DetalheInteracao
					v-for="(interacao, index) in interacoes"
					:key="index"
					:requ-id="id"
					:interacao="interacao"
				/>

				<DetalheAnexo
					:anexos="anexos"
					:requ-id="id"
				/>
			</div>
		</div>

		<div
			v-if="!isModal"
			class="columns"
		>
			<div class="column is-offset-8 is-4 is-offset-0-mobile is-12-mobile">
				<div class="buttons is-centered">
					<b-button
						class="is-primary is-rounded is-fullwidth"
						@click="$emit('createNew')"
					>
						Fazer nova solicitação
					</b-button>
				</div>
			</div>
		</div>

		<div
			v-if="isModal"
			class="columns mb-3 mx-0"
		>
			<div class="column is-offset-8 is-4 is-offset-0-mobile is-12-mobile">
				<div class="buttons is-centered">
					<b-button
						class="is-primary is-rounded is-fullwidth"
						@click="$emit('nextStep')"
					>
						Avaliar
					</b-button>
				</div>
			</div>
		</div>

		<AcompanhamentoFeedbackModal
			v-if="!isModal"
			:requ-id="id"
			@handleDetailAction="loadDetailData"
		/>
	</div>
</template>

<script>
import Icon from '../Icon.vue';
import DetalheInfo from './DetalheInfo';
import DetalheInteracao from './DetalheInteracao';
import DetalheAnexo from './DetalheAnexo';
import { mapGetters, mapMutations } from 'vuex';
import AcompanhamentoFeedbackModal from '@components/solicitacoes/modal/AcompanhamentoFeedbackModal';
import { isPlainObject } from 'lodash';

export default {
	name: 'Detalhe',

	components: {
		DetalheInfo,
		DetalheInteracao,
		DetalheAnexo,
		AcompanhamentoFeedbackModal,
		Icon
	},

	props: {
		id: {
			type: Number,
			required: true,
		},
		isModal: {
			type: Boolean,
			required: false,
			default: false,
		},
	},

	data() {
		return {
			loading: false,
			avaliado: false,
		};
	},

	computed: {
		...mapGetters('ui', {
			isMobile: 'isMobile',
		}),

		solicitacao() {
			if (this.loading) {
				return false;
			}

			return this.$store.getters['solicitacoes/detalhes'](this.id);
		},
		avaliacaoNota() {
			if (this.solicitacao.avaliacao.reav_nosso_resolver == null || this.solicitacao.avaliacao.reav_nosso_resolver == '') {
				return '';
			}
			switch (this.solicitacao.avaliacao.reav_nosso_resolver) {
				case 'muito ruim':
					return 'one';
				case 'ruim':
					return 'two';
				case 'razoável':
					return 'three';
				case 'bom':
					return 'four';
				case 'muito bom':
					return 'five';
				default:
					return '';
			}


		},
		textoNota() {
			if (this.solicitacao.avaliacao.reav_nosso_resolver == null || this.solicitacao.avaliacao.reav_nosso_resolver == '') {
				return '';
			}
			return this.solicitacao.avaliacao.reav_nosso_resolver.charAt(0).toUpperCase() + this.solicitacao.avaliacao.reav_nosso_resolver.slice(1);

		},

		interacoes() {
			if (this.loading) {
				return [false];
			}

			const iteracoes = [];

			let i = 0;

			for (const iteracao of this.solicitacao.movimentos) {
				if (i++ == 0) {
					iteracoes.push({
						...iteracao,
						reqm_parecer: this.solicitacao.requ_descricao_requerimento,
					});

					continue;
				}

				iteracoes.push(iteracao);
			}

			return iteracoes;
		},

		anexos() {
			if (this.loading) {
				return [];
			}

			return this.solicitacao.anexos;
		},

		showButtonNewFeedback() {

			if (!this.loading &&
				this.solicitacao.avaliacao == null &&
				this.avaliado == false &&
				this.solicitacao.movimentos[this.solicitacao.movimentos.length - 1].reqs_descricao_web.toUpperCase() === 'FINALIZADO') {

				// Conforme alinhado com negócio, desconsiderar regra de prazo na página de detalhes
				// Mantendo comentado caso eles mudem de ideia novamente
				//
				// if(this.solicitacao != undefined &&
				// 	this.solicitacao.movimentos != undefined &&
				// 	this.solicitacao.movimentos.length > 0) {

				// 	const [ movimentacao ] = this.solicitacao.movimentos.slice(-1);
				// 	if (movimentacao != null) {
				// 		const dataFinalizado = Date.parse(movimentacao.data_hora);
				// 		let dataPrazo = dataFinalizado + 10 * 24 * 60 * 60;
				// 		let today = Date.now();

				// 		if(dataPrazo < today) return false;
				// 	}

				// }

				return true;
			}
			return false;
		},



		hasFeedback() {
			return this.solicitacao.avaliacao != null && isPlainObject(this.solicitacao.avaliacao)
		}
	},

	async beforeMount() {
		await this.loadDetailData()
	},

	methods: {
		...mapMutations('solicitacoes', [
			'setOpenModalFeedback'
		]),

		handleModalFeedback() {
			this.setOpenModalFeedback(true)
		},

		async loadDetailData() {
			this.loading = true;

			try {
				await this.$store.dispatch('solicitacoes/carregaSolicitacaoDetalhe', this.id);
			} catch (e) {
				this.$store.dispatch('ui/toast/openError', 'Não foi possível carregar essa solicitação, tente novamente ou entre em contato pelo CAA');
			} finally {
				this.loading = false
			}
		},
	}
}
</script>


<style>
.vertical-divider-feedback {
	width: 2px !important;
	height: 100px !important;
	margin: 0.3rem 0;
}
</style>
