<template>
	<button 
		class="image-icon"
		@click="$emit('click')" 
		@mouseover="hover = true"
		@mouseleave="hover = false"
	>
		<inline-svg
			:width="width"
			:height="height"
			:src="require(`../../../assets/${activeFile}.svg`)"
		/>
	</button>
</template>

<script>
import InlineSvg from 'vue-inline-svg';

export default {
	name: 'IconButton',
	components:{
		InlineSvg
	},
	props: {
		file:{
			type: [String],
			default: 'default',
			required: true,
			useDefaultForNull: true
		},
		fileActive:{
			type: [String],
			default: 'default',
			required: true,
			useDefaultForNull: true
		},
		fileHover:{
			type: [String],
			default: null,
			required: false,
			useDefaultForNull: false
		},
		fileActiveHover:{
			type: [String],
			default: null,
			required: false,
			useDefaultForNull: false
		},
		hoverSize:{
			type: [Number],
			default: 0,
			required: false,
			useDefaultForNull: true
		},
		height: {
			type: [Number],
			default: 40,
			required: false,
			useDefaultForNull: true
		},
		width: {
			type: [Number],
			default: 40,
			required: false,
			useDefaultForNull: true
		},
		active: {
			type: [Boolean],
			default: false,
			required: false,
			useDefaultForNull: true
		}
	},
	data() {
		return {
			hover: false
		}
	},
	computed: {
		activeFile(){

			if(this.hover && this.fileActiveHover != null && this.fileHover != null) {
				return this.active?this.fileActiveHover:this.fileHover;
			} else {
				return this.active?this.fileActive:this.file;
			}
		},
	}
	
}
</script>

<style lang="scss" scoped>
  .image-icon {
    cursor:pointer;
	border-style: none;
	border-radius: 50%;
	background-color: transparent;
  }
</style>
