<template>
	<div
		v-if="!hideOnSinglePage || (hideOnSinglePage && totalPages > 1)"
		class="custom-pagination"
	>
		<button
			class="custom-pagination-first-button"
			:disabled="currentPage === 0"
			@click="$emit('changePage', 0)"
		>
			<span class="is-size-6">Primeira</span>
		</button>

		<button
			class="custom-pagination-back-button"
			:disabled="(currentPage - 1) < 0"
			@click="$emit('changePage', currentPage - 1)"
		>
			<b-icon
				class="is-size-6"
				icon="chevron-left"
			/>
		</button>

		<button
			v-for="page in pages"
			:key="page"
			:disabled="currentPage === (page - 1)"
			:class="{
				selected: currentPage === (page - 1),
				'is-hidden-mobile': currentPage !== (page - 1),
			}"
			@click="$emit('changePage', page - 1)"
		>
			<span class="is-size-6">{{ page }}</span>
		</button>

		<button
			class="custom-pagination-next-button"
			:disabled="(currentPage + 1) >= totalPages"
			@click="$emit('changePage', currentPage + 1)"
		>
			<b-icon
				class="is-size-6"
				icon="chevron-right"
			/>
		</button>

		<button
			class="custom-pagination-last-button"
			:disabled="(totalPages - 1) <= currentPage"
			@click="$emit('changePage', totalPages - 1)"
		>
			<span class="is-size-6">Última</span>
		</button>
	</div>
</template>

<script>
const createArray = (size, start) => {
	const arr = Array(size);

	for (let i = 0; i < size; i++) {
		arr[i] = i + start;
	}

	return arr;
};

export default {
	name: 'Pagination',

	model: {
		prop: 'currentPage',
		event: 'changePage'
	},

	props: {
		currentPage: {
			type: Number,
			required: true,
		},

		total: {
			type: Number,
			required: true,
		},

		perPage: {
			type: Number,
			required: true,
		},

		hideOnSinglePage: {
			type: Boolean,
			required: false,
			default: false,
		},

		toShow: {
			type: Number,
			required: false,
			default: 5,
		},
	},

	computed: {
		totalPages() {
			return Math.ceil(this.total / this.perPage);
		},

		pages() {
			if (this.totalPages <= this.toShow) {
				return createArray(this.totalPages, 1);
			}

			const halfShow = Math.floor(this.toShow / 2);

			if (this.currentPage < halfShow) {
				return createArray(this.toShow, 1);
			}

			if (this.currentPage + halfShow >= this.totalPages) {
				return createArray(this.toShow, this.totalPages - this.toShow + 1);
			}

			return createArray(this.toShow, this.currentPage - halfShow + 1);
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@styles/_variables";

.custom-pagination {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	align-content: flex-start;
	// overflow: hidden;
	// overflow-x: scroll;

	button {
		padding: 1rem;
		background-color: var(--white);
		color: #4D4D4D;
		border: 1px solid var(--gray-20);
		border-left-width: 0;
		border-collapse: collapse;
		cursor: pointer;

		&:disabled {
			color: #C2C2C2;
			cursor: default;
		}

		&:hover:not(:disabled) {
			background-color: var(--primary-hover);
			color: var(--white) !important;
		}

		&.selected {
			background-color: var(--primary) !important;
			color: var(--white) !important;
		}

		span {
			height: 1.5rem !important;
			align-items: center;
			display: inline-flex;
			justify-content: center;
		}
	}

	&-first-button {
		border-left-width: 1px !important;
	}

	&-back-button,
	&-next-button {
		padding: 1rem 0.5rem !important;
	}
}
</style>
